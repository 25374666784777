import { defineStore } from "pinia"
import { Router } from "vue-router"

import store from "@/store"
import { activeSystemStorage } from "@/utils/storage"
import {
  postAuthLoginV1 as login,
  type RequestDataType as LoginRequestDataType,
} from "@/services/userCenter/mods/authorization/postAuthLoginV1"
import { getAccountMyselfV1 as getAccountData } from "@/services/userCenter/mods/account/getAccountMyselfV1"
import { getResourceSystemV1 as getSystemData } from "@/services/userCenter/mods/resource/getResourceSystemV1"
import { postAuthLogoutV1 as logout } from "@/services/userCenter/mods/authorization/postAuthLogoutV1"
import { setToken, removeToken, getToken } from "@/utils/auth"

import { LoginResponse } from "./types"
import { treeToList } from "@/utils/tree"

type StateType = {
  token?: string
  refreshToken?: string
  user?: LoginResponse["account"]
  mainMenus: any[]
  activeSystem?: string
  mainMenuFlatList: string[]
  dynamicRoutes: any[]
  // 动态分配的所有权限列表
  authCodeList: string[]
}

export const useAppStore = defineStore({
  id: "burnish-app",
  state: (): StateType => ({
    token: getToken()?.accessToken,
    refreshToken: "",
    user: null,
    activeSystem: activeSystemStorage.get(),
    mainMenus: [],
    mainMenuFlatList: [],
    dynamicRoutes: [],
    authCodeList: [],
  }),
  actions: {
    async getUserData(isForce = false) {
      if (this.user && !isForce) return this.user

      const data = await getAccountData({ showLoading: true })

      this.user = data
      return data
    },

    /** 登入 */
    async login(type: string, params) {
      let _params: any

      if (type == "password") {
        _params = {
          identifier: params.username,
          login_type: "password",
          payload: {
            password: params.password,
            code: params.verifyCode,
            unique_key: params.verifyCodeKey,
          },
        }
      } else {
        _params = {
          identifier: params.phone,
          login_type: "verify_code",
          payload: {
            code: params.code,
          },
        }
      }
      _params["scene"] = "Web"

      const data = await this._login(_params)

      return data.account
    },
    async _login(data: LoginRequestDataType) {
      const loginData = await login<defs.userCenter.AuthData>({
        data,
        withToken: false,
      })

      this.token = loginData.access_token
      this.user = loginData.account

      setToken({
        accessToken: loginData.access_token,
        username: loginData.account.username,
        expires: loginData.expired_at,
      })

      return loginData
    },

    async fetchUserData() {
      const params = activeSystemStorage.get()
        ? {
            params: {
              system_id: activeSystemStorage.get(),
            },
          }
        : {}
      const user = await getAccountData(params)

      this.user = user
    },
    async fetchSystemData() {
      const data: SystemResourceItem[] = await getSystemData({
        params: {
          system_id: activeSystemStorage.get(),
        },
      })
      const flatSystemData = treeToList(data)
      this.mainMenuFlatList = flatSystemData.filter(item => item.type == "menu").map(item => item.code)
      this.authCodeList = flatSystemData.map(item => item.code)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    async logout(router: Router) {
      logout({
        data: {
          scene: "Web",
        },
      }).finally(() => {
        this.clearLoginData()
        location.href = "/"
      })
    },
    clearLoginData() {
      this.token = null
      this.user = null
      this.activeSystem = null
      activeSystemStorage.remove()
      removeToken()
    },
    setMainMenu(menus: any[]) {
      this.mainMenus = menus
    },
    setDynamicRoutes(routes: any[]) {
      this.dynamicRoutes = routes
    },
  },
})

export function useAppStoreHook() {
  return useAppStore(store)
}
