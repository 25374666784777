<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"

import { useAppStoreHook } from "@/store/app"
import AvatarSuperAdminUri from "@app/common/assets/home/avatar-super-admin.png"
import AvatarComanyAdminUri from "@app/common/assets/home/avatar-company-admin.png"

type EmitType = {
  (type: "confirm", role: string): void
}
const emits = defineEmits<EmitType>()
const visible = defineModel<boolean>({
  default: false,
})
const router = useRouter()

const { logout } = useAppStoreHook()
const roleChoose = ref()

function onRoleChoose() {
  if (!roleChoose.value) return

  emits("confirm", roleChoose.value)
}
</script>

<template>
  <ElDialog
    class="home-role-choose-dialog"
    v-model="visible"
    :width="992"
    align-center
    :close-on-click-modal="false"
    :show-close="false"
    :close-on-press-escape="false"
  >
    <div class="flex flex-col items-center justify-center h-full text-black">
      <div class="text-xl leading-[26px]">您的账号有多个角色，请选择登录的角色</div>
      <div class="flex flex-nowrap mt-[60px]">
        <div
          class="w-[166px] h-[160px] rounded-xl flex flex-col items-center justify-center mr-[45px] cursor-pointer"
          :class="{ 'role-active': roleChoose == 'admin' }"
          @click="() => (roleChoose = 'admin')"
        >
          <img :src="AvatarSuperAdminUri" alt="" class="object-cover w-[90px]" />
          <div class="text-xs leading-4 mt-[15px]">超级管理员</div>
        </div>
        <div
          class="w-[166px] h-[160px] rounded-xl flex flex-col items-center justify-center cursor-pointer"
          :class="{ 'role-active': roleChoose == 'company' }"
          @click="() => (roleChoose = 'company')"
        >
          <img :src="AvatarComanyAdminUri" alt="" class="object-cover w-[90px]" />
          <div class="text-xs leading-4 mt-[15px]">企业用户</div>
        </div>
      </div>
      <ElButton type="primary" class="mt-[75px] w-[250px]" :disabled="!roleChoose" @click="onRoleChoose">确定</ElButton>
      <div>
        <ElButton class="mt-[20px] w-[250px]" @click="() => logout(router)">退出登录</ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<style lang="scss">
.home-role-choose-dialog {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__title {
    font-weight: bold;
  }

  .el-dialog__body {
    height: 543px;
  }

  .role-active {
    border: 1px solid #4181d9;
  }
}
</style>
