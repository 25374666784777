import { userCenterRequest as request } from "@/utils/request"
import { PureHttpRequestConfig } from "@/utils/request/type.d"

export type RequestParamsType = any
export type RequestDataType = defs.userCenter.CompanyAddSystemApplyCreate
export type RequestConfigType = Omit<PureHttpRequestConfig, "params" | "data"> & {
  data?: RequestDataType
  params?: RequestParamsType
}

/**
 * @desc 创建企业新增系统申请
 */
export function postSystemApplyV1<T = any>(config?: RequestConfigType) {
  return request.request<T>("post", `/v1/system/apply`, config)
}
