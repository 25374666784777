import { RouteRecordRaw, RouterOptions, createWebHashHistory, createRouter as createRawRouter, Router } from "vue-router"
import { createNotFoundRoute, defRoutes, NOT_FOUND_PATH_NAME } from "./index"

/**
 * 创建路由：由于本项目项目中涉及到多级路由分发，因此在此处统一封装
 * @param routes
 * @returns
 */
export function createRouter(routes: RouteRecordRaw[]) {
  const router = createRawRouter({
    history: createWebHashHistory(),
    routes: routes as any,
    strict: true,
    scrollBehavior(to, from, savedPosition) {
      return new Promise(resolve => {
        if (savedPosition) {
          return savedPosition
        } else {
          if (from.meta.saveSrollTop) {
            const top: number = document.documentElement.scrollTop || document.body.scrollTop
            resolve({ left: 0, top })
          }
        }
      })
    },
  } as RouterOptions)

  return router
}

/**
 * 路由重置，移除未包含在默认路由里面的路由配置
 * @param router
 * @param defaultRoutes
 */
export function resetRouter(router: Router, defaultRoutes: RouteRecordRaw[] = defRoutes) {
  const defaultPaths = defaultRoutes.map(item => item.path)

  router.getRoutes().forEach(route => {
    if (!defaultPaths.includes(route.path)) {
      router.removeRoute(route.name)
    }
  })

  // 加入404页面
  router.addRoute(createNotFoundRoute())
}

/**
 * 移除404页面，一般在登录后添加动态路由前，需要先移除404路由配置项
 * @param router
 */
export function removeNotFoundRoute(router: Router) {
  router.removeRoute(NOT_FOUND_PATH_NAME)
}
