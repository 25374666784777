import { RouteRecordRaw } from "vue-router"

import Error403 from "@app/common/pages/error/403.vue"
import Error404 from "@app/common/pages/error/404.vue"
import Error500 from "@app/common/pages/error/500.vue"
import LoginPage from "@app/common/pages/login/index.vue"
import RegisterPage from "@app/common/pages/register/index.vue"
import ResetPage from "@app/common/pages/reset/index.vue"
import SystemSelectPage from "@app/common/pages/home/index.vue"

export const defRoutes = [
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterPage,
    meta: {
      title: "注册",
      showLink: false,
      rank: 101,
    },
  },
  {
    path: "/reset",
    name: "Reset",
    component: ResetPage,
    meta: {
      title: "忘记密码",
      showLink: false,
      rank: 101,
    },
  },
  {
    path: "/error/403",
    name: "403",
    component: Error403,
    meta: {
      title: "403",
      showLink: false,
    },
  },
  {
    path: "/error/404",
    name: "404",
    component: Error404,
    meta: {
      title: "404",
      showLink: false,
    },
  },
  {
    path: "/error/500",
    name: "500",
    component: Error500,
    meta: {
      title: "500",
      showLink: false,
    },
  },
]
export const LOGIN_URL = "/login"
export const WHITE_LIST = [LOGIN_URL, "/register", "/reset", "/error/404", "/error/403", "/error/500"]
export const NOT_FOUND_URL = "/:pathMatch(.*)*"
export const NOT_FOUND_PATH_NAME = "NotFound"
export const SYSTEM_SELECT_URL = "/system-select"

export function createSystemSelectRoute(): RouteRecordRaw {
  return {
    path: SYSTEM_SELECT_URL,
    name: "system-select",
    meta: { title: "未找到该页面" },
    component: SystemSelectPage,
  }
}

export function createNotFoundRoute(): RouteRecordRaw {
  return {
    path: NOT_FOUND_URL,
    name: NOT_FOUND_PATH_NAME,
    meta: { title: "未找到该页面" },
    component: Error404,
  }
}
