// https://github.com/VincentSit/ChinaMobilePhoneNumberRegex
export const phoneReg = /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/
// 8-20位：至少一个数字、一个字母、一个特殊符号
// export const pwdReg = /^\S*(?=\S{8,20})(?=\S*\d)(?=\S*[A-Za-z])(?=\S*[(),._<>~@!#$%^&*?])\S*$/
// 8-20位：数字、大写字母、小写字母、符号（(),._<>~@!#$%^&*?）的任意三者组合
export const pwdReg =
  /(?!^[0-9A-Z]+$)(?!^[0-9a-z]+$)(?!^[0-9'(),._<>~@!#$%^&*?"/·;:-]+$)(?!^[A-Za-z]+$)(?!^[A-Z'(),._<>~@!#$%^&*?"/·;:-]+$)(?!^[a-z'(),._<>~@!#$%^&*?"/·;:-]+$)(^[A-Za-z0-9'(),._<>~@!#$%^&*?"/·;:-]{8,20}$)/

// 校验URL：支持 http、https、ftp、ftps
export const urlReg = /^(ht|f)(tp|tps):\/\/[a-zA-Z0-9\-.]+\.([a-zA-Z]{2,3})?(\/\S*)?$/

// 邮箱校验
export const mailReg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const idCardReg = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/

export const validatePhone = (rule: any, value: any, callback: any) => {
  if (value === "") {
    callback(new Error("请输入联系人电话"))
  } else {
    if (phoneReg.test(value)) {
      callback()
    } else {
      callback(new Error("请输入正确的电话"))
    }
  }
}
