import { PersistentLocalStorage, BaseStorage, TemporaryLocalStorage } from "./cache"
import {
  ACTIVE_SYSTEM_CACHE_ID,
  LOGIN_DATA,
  CITY_LIST,
  CITY_LIST_AREA_CODE,
  REGION_LIST,
  MAX_COUNT_COMPANY,
} from "@/config/cache"

type LoginDataType = {
  username: string
  password: string
  rememberPassword?: boolean
}

export const loginData = new BaseStorage<LoginDataType>(LOGIN_DATA, PersistentLocalStorage)
export const cityList = new BaseStorage<any>(CITY_LIST, PersistentLocalStorage)
export const cityListWithAreaCode = new BaseStorage<any>(CITY_LIST_AREA_CODE, PersistentLocalStorage)
export const RegionList = new BaseStorage<any>(REGION_LIST, PersistentLocalStorage)

export const activeSystemStorage = new BaseStorage<string>(ACTIVE_SYSTEM_CACHE_ID, TemporaryLocalStorage)

export const maxCountCompanyStorage = new BaseStorage<string>(MAX_COUNT_COMPANY, TemporaryLocalStorage)
