<script setup lang="ts">
import { reactive, ref } from "vue"
import { useRouter } from "vue-router"
import Motion from "@/components/Motion"

import { type FormRules, type FormInstance, ElMessage } from "element-plus"
import { pwdReg } from "@/utils/regex"
import { postAuthPasswordCodeChangeV1 } from "@/services/userCenter/mods/authorization/postAuthPasswordCodeChangeV1"

import { Step1FormValue, Step2FormValue, Step2EmitType } from "./type"
import { View, Warning } from "@element-plus/icons-vue"

defineOptions({
  name: "ForgetStep1",
})

const props = defineProps<Step1FormValue>()
const formRef = ref<FormInstance>()
const formValues = reactive<Step2FormValue>({})
const router = useRouter()
const emits = defineEmits<Step2EmitType>()
const loading = ref(false)

const rules = reactive<FormRules>({
  password: [
    {
      required: true,
      message: "请输入新密码",
      trigger: "blur",
    },
    {
      validator: (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入新密码"))
        } else if (!pwdReg.test(value)) {
          callback(new Error("请输入有效的新密码"))
        } else {
          callback()
        }
      },
      trigger: "blur",
    },
  ],
  confirmPassword: [
    {
      required: true,
      message: "输入重复密码",
      trigger: "blur",
    },
    {
      validator: (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入重复密码"))
        } else if (!pwdReg.test(value)) {
          callback(new Error("请输入有效的重复密码"))
        } else {
          callback()
        }
      },
      trigger: "blur",
    },
  ],
})

function onConfirm() {
  formRef.value?.validate().then(isValid => {
    if (isValid) {
      loading.value = true
      postAuthPasswordCodeChangeV1({
        data: {
          code: props.code,
          identifier: props.phone,
          new_password: formValues.password,
          confirm_password: formValues.confirmPassword,
        },
        withToken: false,
      })
        .then(() => {
          ElMessage.success("密码修改成功")
          router.push("/login")
        })
        .finally(() => {
          loading.value = false
        })
    }
  })
}

function onCancel() {
  emits("back")
}
const newpassType = ref<string>("password")
const onChangeNewPass = () => {
  newpassType.value = newpassType.value == "password" ? "text" : "password"
}
const confirmpassType = ref<string>("password")
const onChangeconfirmPass = () => {
  confirmpassType.value = confirmpassType.value == "password" ? "text" : "password"
}
</script>

<template>
  <div class="forget-step forget-step2-com">
    <ElForm ref="formRef" :model="formValues" :rules="rules" hide-required-asterisk label-width="100px">
      <Motion>
        <ElFormItem prop="password" label="新密码" class="newpassword">
          <ElInput v-model="formValues.password" placeholder="输入新密码" :type="newpassType" clearable>
            <template #suffix>
              <el-icon style="vertical-align: middle" @click="onChangeNewPass">
                <View />
              </el-icon>
            </template>
          </ElInput>
          <el-tooltip
            content="密码规则：（8-20位）至少包含数字、小写字母、大写字母、特殊字符任意三项"
            placement="top"
            effect="light"
          >
            <el-icon class="tips cursor-pointer" color="#409EFC"><Warning /></el-icon>
          </el-tooltip>
        </ElFormItem>
      </Motion>
      <Motion :delay="100">
        <ElFormItem prop="password" label="重复密码">
          <ElInput v-model="formValues.confirmPassword" placeholder="输入重复密码" :type="confirmpassType" clearable>
            <template #suffix>
              <el-icon style="vertical-align: middle" @click="onChangeconfirmPass">
                <View />
              </el-icon>
            </template>
          </ElInput>
        </ElFormItem>
      </Motion>
    </ElForm>
    <Motion :delay="200">
      <div class="forget-buttons flex justify-between mt-[100px]">
        <ElButton class="flex-1" @click="onCancel" :loading="loading">上一步</ElButton>
        <span class="flex-none w-[10px]" />
        <ElButton @click="onConfirm" type="primary" class="flex-1" :loading="loading">确定</ElButton>
      </div>
    </Motion>
  </div>
</template>
<style lang="scss" scoped>
.newpassword {
  position: relative;

  .tips {
    position: absolute;
    top: 8px;
    right: -24px;
  }
}
</style>
