<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import { Select } from "@element-plus/icons-vue"

import SimpleLayout from "@/components/SimpleLayout"
import { postSystemApplyV1 } from "@/services/userCenter/mods/system/postSystemApplyV1"
import { useAppStore } from "@/store/app"
import { unref } from "vue"
import { ElMessage } from "element-plus"
import RoleChooseDialog from "./RoleChooseDialog.vue"

type SystemListItem = defs.userCenter.SystemList & {
  accessible: boolean
}

defineOptions({
  name: "Welcome",
})

const activeSystems = ref<string[]>([])
const loading = ref(false)
const platformChooseVisible = ref(false)
const platformActiveRole = ref<"admin" | "company">()
const { user } = useAppStore()
const isSuperAdmin = computed(() => user?.is_super_admin)
// const adminSystemList = computed<SystemListItem[]>(() => {
//   const _systemList = user?.systems ?? []

//   // 如果是整个平台超管：需要先选择登录角色
//   if (isSuperAdmin.value) {
//     if (platformActiveRole.value) {
//       return _systemList.filter(item => item.internal && item.status == "enable")
//     }

//     return []
//   }

//   return []
// })
const systemList = computed<SystemListItem[]>(() => {
  const _systemList = user?.systems ?? []

  // 如果是整个平台超管：需要先选择登录角色
  if (isSuperAdmin.value) {
    if (platformActiveRole.value) {
      return _systemList.filter(
        item => (platformActiveRole.value == "admin" ? item.internal : !item.internal) && item.status == "enable",
      )
    }

    return []
  }

  return _systemList.filter(item => !item.internal && item.status == "enable")
})

function onSytemItemClick(item: SystemListItem) {
  if (item.accessible) {
    activeSystems.value = []

    if (item.front_route == "/city-express/") {
      window.location.href =
        "http://lk.sevdata.cn:8301/freight?token=" +
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzAwNDc1MjI3LCJqdGkiOiJmNWVhODM1NGU1NTU0NzNiODZmYzMwYzhjMWU3YmEyMyIsInVzZXJfaWQiOjE3fQ.aMlsNTN9a7wRoegtStllxohrHV_YdHtEk7kTaSxsfnE"
    } else {
      // devServer中的字路由页面必须以/结尾，不然会跳转到根目录下
      let front_route = item.front_route.trim()
      if (!front_route.endsWith("/")) {
        front_route += "/"
      }
      if (!front_route.startsWith("/")) {
        front_route = "/" + front_route
      }

      window.location.href = front_route
    }

    return
  }

  if (user.is_super_admin) {
    if (activeSystems.value.includes(item.id)) {
      activeSystems.value = activeSystems.value.filter(_item => _item != item.id)
    } else {
      activeSystems.value.push(item.id)
    }
  }
}

function onSubmit() {
  loading.value = true
  postSystemApplyV1({
    data: {
      systems: unref(activeSystems),
    },
  })
    .then(() => {
      ElMessage.success("申请提交成功")
    })
    .finally(() => {
      loading.value = false
    })
}

function onRoleChoose(role) {
  platformActiveRole.value = role
  platformChooseVisible.value = false
}

onMounted(() => {
  // 如果是整个平台超管，则让其先选择登录平台，再选择登录系统
  if (isSuperAdmin.value) {
    //判断 //超管平台，是否有权限，是否禁用
    const adminSystemList = user?.systems.filter(item => item.internal && item.accessible && item.status)

    // 如果后台系统长度大于1，就显示弹窗让其选择登录角色。如果长度为0，则直接默认显示为企业用户
    if (adminSystemList.length > 0) {
      platformChooseVisible.value = true
    } else {
      platformActiveRole.value = "company"
    }
  }
})
</script>

<template>
  <SimpleLayout class="welcome-pg" title="请选择要登录的系统">
    <div class="welcome-content">
      <ElScrollbar class="welcome-scrollbar">
        <div class="welcome-list">
          <div
            v-for="(item, index) in systemList"
            :key="item.id"
            class="flex flex-col items-center welcome-list-item"
            :class="{ 'welcome-list-item-active': activeSystems.includes(item.id) || item.accessible }"
            @click="() => onSytemItemClick(item)"
            :style="`margin-right: ${index != systemList.length - 1 ? 20 : 0}px`"
          >
            <img :src="item.icon" v-if="item.icon" class="welcome-list-item-icon" />
            <div class="welcome-list-item-title">{{ item.label }}</div>
            <div class="welcome-list-item-description">{{ item.description }}</div>
            <ElIcon :size="40" color="#4181d9" v-if="activeSystems.includes(item.id)" class="welcome-list-item-checked">
              <Select />
            </ElIcon>
          </div>
        </div>
      </ElScrollbar>
      <div
        class="flex justify-center mt-[100px]"
        v-if="(!isSuperAdmin && user.is_company_super_admin) || (isSuperAdmin && platformActiveRole == 'company')"
      >
        <ElButton class="welcome-btn" :disabled="activeSystems.length == 0" type="primary" @click="onSubmit" :loading="loading"
          >需要增加系统权限请点击申请</ElButton
        >
      </div>
    </div>
  </SimpleLayout>
  <RoleChooseDialog v-model="platformChooseVisible" @confirm="onRoleChoose" />
</template>

<style lang="scss">
.welcome-pg {
  .el-form-item__label {
    font-weight: 700;
  }

  .welcome {
    &-scrollbar {
      max-width: 90vw;
      margin-bottom: 200px;
    }

    &-list {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      padding-top: 50px;

      &-item {
        position: relative;
        flex: none;
        width: 260px;
        height: 312px;
        padding: 0 20px;
        padding-bottom: 23px;
        cursor: pointer;
        border: 1px solid #afb6bf;
        border-radius: 12px;
        opacity: 0.5;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-active {
          border-color: #4181d9;
          opacity: 1;

          .welcome-list-item-icon {
            opacity: 1;
          }
        }

        &-icon {
          width: 200px;
          height: 160px;
          opacity: 0.5;
        }

        &-title {
          margin-top: 24px;
          margin-bottom: 19px;
          font-size: 20px;
          line-height: 21px;
          color: #000;
          text-align: center;
        }

        &-description {
          font-size: 14px;
          line-height: 21px;
          color: #979da5;
        }

        &-checked {
          position: absolute;
          top: -60px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>
